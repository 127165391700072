import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import InternalPage from './InternalPage';
import CreateMassImportModal from '../components/modals/massImport/CreateMassImportModal';
import MassImportList from '../components/massImport/MassImportList';
import MassImportDetails from '../components/massImport/MassImportDetails';
import { useCreateDeaImportMutation, useDeleteDeaImportMutation, useGetAllDeaImportsQuery, useProcessDeaImportMutation } from '../store/api/deaImportApi';
import { IImportTypeOption } from '../models/MassImport/IImportTypeOption';
import { MassImportType } from '../models/MassImport/MassImportType';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { API_URL } from './../config';

const importTypes: IImportTypeOption[] = [
    { importType: MassImportType.DEA, typeTitle: 'DEA' },
]

const ImportDeaPage = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [createMassImportVisible, setCreateMassImportVisible] = useState<boolean>(false);
    const [showImportDetails, setShowImportDetails] = useState<boolean>(false);
    const [importDetailsId, setImportDetailsId] = useState<string|null>(null)
    
    const { data, isError, isFetching, refetch } = useGetAllDeaImportsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [startProcessing, { isLoading, error }] = useProcessDeaImportMutation();
    const [deleteImport, { isLoading: isDeleting, error: deletError }] = useDeleteDeaImportMutation()

    useEffect(() => {
        const {id} = params;
        if(id){
            showImportDetailsBlock(id);
        }
    }, [])
    

    const onStartButtonClick = async (id: string) => {

        try {
            await startProcessing(id);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const onDownloadProcessedButtonClick = async (id: string) => {

        try {
            // const response = await axios.get(`/download/${id}`, {
            const response = await axios.get(API_URL +`/dea-import/download-processed/${id}`, {
                responseType: 'blob', // Ensure the response is treated as a binary blob
            });
            // Use FileSaver to trigger the file download
            saveAs(response.data, `ProcessedFile-${id}.csv`);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    const onDeleteButtonClick = async (id: string) => {
        
        try {
            await deleteImport(id);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const onDetailsButtonClick = (id:string) => {
        console.log(id);
        showImportDetailsBlock(id);
        navigate(`/dea-imports/${id}`)
    }

    const handleClose = () => {
        setShowImportDetails(false);
        navigate('/dea-imports')
    }

    const showImportDetailsBlock = (id: string) => {
        setShowImportDetails(true);
        setImportDetailsId(id);
    }

    return (
        <InternalPage>
            <div className='title-block' style={{backgroundImage: "url(/static/img/title-bg-1.png)"}}>
                <h1>
                    Import and check DEA
                </h1>
                <div className="title-buttons">
                    <Button className='std-button' style={{ borderColor: '#ffffff', fontWeight: 'bold' }} onClick={() => setCreateMassImportVisible(true)}>Upload new data</Button>
                </div>
            </div>
            
            <div className='main-content'>
                <Card className='w-100 p-3'>
                    

                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError 
                        && <MassImportList 
                                imports={data.imports}
                                isAlreadyProcessing={data.isAlreadyProcessing}
                                onStartButtonClick={onStartButtonClick}
                                onDetailsButtonClick={onDetailsButtonClick}
                                onDeleteButtonClick={onDeleteButtonClick}
                                onDownloadProcessedButtonClick={onDownloadProcessedButtonClick}/>
                    }
                </Card>

            </div>
            <CreateMassImportModal
                show={createMassImportVisible}
                onHide={() => setCreateMassImportVisible(false)}
                onSuccess={() => setCreateMassImportVisible(false)}
                importTypes={importTypes}
                createImport={useCreateDeaImportMutation}/>

            <MassImportDetails show={showImportDetails} onHide={handleClose} importId={importDetailsId}/>
            
        </InternalPage>

    );
};

export default ImportDeaPage;