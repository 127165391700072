import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormControl, Row, Spinner, Table } from 'react-bootstrap';
import InternalPage from './InternalPage';
import { useCorrectChargebacksImportValuesMutation, useGetCorrectedChargebacksImportValuesQuery } from '../store/api/chargebackApi';
import { IImportTypeOption } from '../models/MassImport/IImportTypeOption';
import { MassImportType } from '../models/MassImport/MassImportType';
import { ICorrectedChargebackValue } from './../models/ICorrectedChargebackValue';
import { useForm } from 'react-hook-form';
import { CorrectChargebackImportValuesRequest } from '../store/api/types';
import { createCorrectImportValuesValidationSchema } from '../utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { CorrectChargebackImportValuesType } from '../models/enums/CorrectChargebackImportValuesType';

const importTypes: IImportTypeOption[] = [
    { importType: MassImportType.CHARGEBACK_ICS, typeTitle: 'ICS' },
    { importType: MassImportType.CHARGEBACK_MEDICOMART, typeTitle: 'MEDICOMART' },
    { importType: MassImportType.CHARGEBACK_HENRY_SCHEIN, typeTitle: 'HENRY SCHEIN' },
    { importType: MassImportType.CHARGEBACK_MCKESSON, typeTitle: 'MCKESSON' },
]

const UpdateChargebackImportValuesPage = () => {

    const navigate = useNavigate();
    const params = useParams();
    
    const { data, isError, isFetching, refetch } = useGetCorrectedChargebacksImportValuesQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });

    const [createCorrectValue, { isLoading, error }] = useCorrectChargebacksImportValuesMutation();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<CorrectChargebackImportValuesRequest>({
        resolver: yupResolver(createCorrectImportValuesValidationSchema)
    });

    const onSubmit = async (data: CorrectChargebackImportValuesRequest) => {
        console.log('add corrected values', data);

        try{
            await createCorrectValue(data)
        }
        catch(err){
            console.log(err);

        }
    }

    return (
        <InternalPage>
                <h1>
                    Updated chargeback values
                </h1>
            
            <div className='main-content'>
                <Card className='w-100 p-3'>
                    <Form className='d-flex' onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={3}>
                                <FormControl
                                    {...register('oldValue')}
                                    className={`mt-3 ${errors.oldValue ? 'is-invalid' : ''}`}
                                    placeholder='Old value'
                                />
                                <div className="invalid-feedback align-self-start">{errors.oldValue?.message}</div>
                            </Col>
                            <Col xs={3}>
                                <FormControl
                                    {...register('newValue')}
                                    className={`mt-3 ${errors.newValue ? 'is-invalid' : ''}`}
                                    placeholder='New value'
                                />
                                <div className="invalid-feedback align-self-start">{errors.newValue?.message}</div>
                            </Col>
                            <Col xs={3}>

                                <Form.Select
                                    {...register('type')}
                                    aria-label="Type select"
                                    className={`mt-3 ${errors.type ? 'is-invalid' : ''}`}>
                                    <option value={CorrectChargebackImportValuesType.HIN}>HIN</option>
                                    <option value={CorrectChargebackImportValuesType.DEA}>DEA</option>
                                </Form.Select>
                                <div className="invalid-feedback align-self-start">{errors.type?.message}</div>
                            </Col>
                            <Col xs={3}>
                                <Button variant='outline-success' className="mt-3" type="submit" onClick={handleSubmit(onSubmit)}>
                                    {isLoading && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />}
                                    <span className="sr-only">Add</span>
                                </Button>
                            </Col>
                        </Row>
                    </Form>

                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError 
                        && <CorrectedValuesList correctedValues={data.data}/>
                    }
                </Card>

            </div>
            
        </InternalPage>

    );
};

export default UpdateChargebackImportValuesPage;

const CorrectedValuesList: React.FC<{ correctedValues: ICorrectedChargebackValue[] }> = ({ correctedValues }) => {
    console.log('corrected', correctedValues)
    if(correctedValues.length === 0){
        return <div className='mt-3'>There are no items yet.</div>
    }

    // return <div className='mt-3'>
    //     {correctedValues.map(item => {
    //         return <div key={item.id}>{item.oldValue}</div>
    //     })}
    // </div>
    return <Table striped className="mt-3">
        <thead>
            <tr>
                <th>Old value</th>
                <th>New value</th>
                <th>Type</th>
                <th>Updated At</th>
            </tr>
        </thead>
        <tbody>
            {correctedValues.map(item => {
                return <tr key={item.id}>
                    <td>{item.oldValue}</td>
                    <td>{item.newValue}</td>
                    <td>{item.type}</td>
                    <td>{item.updatedAt}</td>
                </tr>
            })}
        </tbody>
    </Table>
}