export enum MassImportType{
    ACCOUNT = 'ACCOUNT',
    CONTACT = 'CONTACT',
    CHARGEBACK_ICS = 'CHARGEBACK_ICS',
    CHARGEBACK_MEDICOMART = 'CHARGEBACK_MEDICOMART',
    CHARGEBACK_HENRY_SCHEIN = 'CHARGEBACK_HENRY_SCHEIN',
    CHARGEBACK_VAXSERVE = 'CHARGEBACK_VAXSERVE',
    CHARGEBACK_MCKESSON = 'CHARGEBACK_MCKESSON',
    SALES_MEDICOMART_WEEKLY = 'SALES_MEDICOMART_WEEKLY',
    DEA = 'DEA',
}