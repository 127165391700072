import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import InternalPage from './InternalPage';
import CreateMassImportModal from '../components/modals/massImport/CreateMassImportModal';
import MassImportList from '../components/massImport/MassImportList';
import MassImportDetails from '../components/massImport/MassImportDetails';
import { useCreateChargebackImportMutation, useDeleteChargebacksImportMutation, useGetAllChargebackImportsQuery, useProcessChargebacksImportMutation } from '../store/api/chargebackApi';
import { IImportTypeOption } from '../models/MassImport/IImportTypeOption';
import { MassImportType } from '../models/MassImport/MassImportType';
import { CHARGEBACK_STATISTIC_ROUTE, UPDATED_CHARGEBACK_VALUES_ROUTE } from '../utils/contsants';
import { IPagedListParams } from '../models/IPagedListParams';
import { PaginationControl } from 'react-bootstrap-pagination-control';

const importTypes: IImportTypeOption[] = [
    { importType: MassImportType.CHARGEBACK_ICS, typeTitle: 'ICS' },
    { importType: MassImportType.CHARGEBACK_MEDICOMART, typeTitle: 'MEDICOMART' },
    { importType: MassImportType.CHARGEBACK_HENRY_SCHEIN, typeTitle: 'HENRY SCHEIN' },
    { importType: MassImportType.CHARGEBACK_VAXSERVE, typeTitle: 'VAXSERVE' },
    { importType: MassImportType.CHARGEBACK_MCKESSON, typeTitle: 'MCKESSON' },
]

const ImportChargebackPage = () => {

    const navigate = useNavigate();
    const params = useParams();

    const [queryParams, setQueryParams] = useState<IPagedListParams>({
        page: 1,
        pageSize: 10,
        sort: 'created_at',
        dir: 'desc'
    });

    const [createMassImportVisible, setCreateMassImportVisible] = useState<boolean>(false);
    const [showImportDetails, setShowImportDetails] = useState<boolean>(false);
    const [importDetailsId, setImportDetailsId] = useState<string|null>(null)
    
    const { data, isError, isFetching, refetch } = useGetAllChargebackImportsQuery(queryParams, {
        refetchOnMountOrArgChange: true,
    });

    const [startProcessing, { isLoading, error }] = useProcessChargebacksImportMutation();
    const [deleteImport, {isLoading: isDeleting, error: deletError}] = useDeleteChargebacksImportMutation()


    useEffect(() => {
        const {id} = params;
        if(id){
            showImportDetailsBlock(id);
        }
    }, [])

    const handleQueryParamsChange = (newParams: Partial<IPagedListParams>) => {
        setQueryParams({ ...queryParams, ...newParams });
    };
    
    const onStartButtonClick = async (id: string) => {

        try {
            await startProcessing(id);
        }
        catch (ex) {
            console.log(ex);
        }

    }

    const onDetailsButtonClick = (id:string) => {
        console.log(id);
        showImportDetailsBlock(id);
        navigate(`/chargebacks/${id}`)
    }

    const onDeleteButtonClick = async(id: string) => {
        try {
            await deleteImport(id);
        }
        catch (ex) {
            console.log(ex);
        }
    }

    const handleClose = () => {
        setShowImportDetails(false);
        navigate('/chargebacks')
    }

    const showImportDetailsBlock = (id: string) => {
        setShowImportDetails(true);
        setImportDetailsId(id);
    }

    return (
        <InternalPage>
            <div className='title-block' style={{backgroundImage: "url(/static/img/title-bg-1.png)"}}>
                <h1>
                    Import chargeback data
                </h1>
                <div className="title-buttons">
                    <Button className='std-button me-2' style={{ borderColor: '#ffffff', fontWeight: 'bold' }} onClick={() => setCreateMassImportVisible(true)}>Upload new data</Button>
                    {/* <Button className='std-button' style={{ borderColor: '#ffffff', fontWeight: 'bold' }} onClick={() => setCreateMassImportVisible(true)}>Statistic</Button> */}
                    <Link className='btn std-button' style={{ borderColor: '#ffffff', fontWeight: 'bold' }} to={CHARGEBACK_STATISTIC_ROUTE}>Statistic</Link>
                </div>
            </div>
            
            <div className='main-content'>
                <Card className='w-100 p-3'>
                    <div className='d-flex flex-row-reverse'>
                        <Link className='std-link' to={UPDATED_CHARGEBACK_VALUES_ROUTE}>Updated values</Link>
                    </div>
                    
                    {isFetching && <div>Loading...</div>}
                    {!isFetching && isError && <div>Oops. Somethins goes wrong.</div>}
                    {data && !isFetching && !isError 
                        && <>
                            <MassImportList 
                                imports={data.imports}
                                isAlreadyProcessing={data.isAlreadyProcessing}
                                onStartButtonClick={onStartButtonClick}
                                onDetailsButtonClick={onDetailsButtonClick}
                                onDeleteButtonClick={onDeleteButtonClick}/>
                            <PaginationControl
                                page={queryParams.page}
                                between={4}
                                total={data.totalItems}
                                limit={queryParams.pageSize}
                                changePage={(page) => {
                                    handleQueryParamsChange({ page })
                                }}
                                ellipsis={1}
                            />
                        </>
                    }
                </Card>

            </div>
            <CreateMassImportModal
                show={createMassImportVisible}
                onHide={() => setCreateMassImportVisible(false)}
                onSuccess={() => setCreateMassImportVisible(false)}
                importTypes={importTypes}
                createImport={useCreateChargebackImportMutation}/>
            <MassImportDetails show={showImportDetails} onHide={handleClose} importId={importDetailsId}/>
            
        </InternalPage>

    );
};

export default ImportChargebackPage;