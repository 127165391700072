import { IChargebackImport } from '../../models/chargeback/ChargebackImport';
import { IDeaImport } from '../../models/MassImport/IDeaImport';
// import { IMassImport } from '../../models/MassImport/IMassImport';
import { api } from './api'
import { IDeaImportListResponse, CreateDeaImportRequest, ProcessDeaImportResponse } from './types';

export const deaImportApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllDeaImports: build.query<IDeaImportListResponse, void>({
            query: () =>  ({ url: '/dea-import/all' }),
            providesTags: ['DeaImport'],
        }),
        // getMassImport: build.query<IMassImport, string|null>({
        //     query: (id: string) => ({ url: '/mass-import/'+id }),
        // }),import { IChargebackImport } from './../../models/chargeback/ChargebackImport';

        createDeaImport: build.mutation<IDeaImport, CreateDeaImportRequest>({
            query: (createInput: CreateDeaImportRequest) => {

                const formData = new FormData();
                formData.append('title', createInput.title);
                formData.append('description', createInput.description);
                formData.append('type', createInput.type);
                formData.append('updateFile', createInput.updateFile);
                formData.append('file', createInput.updateFile);

                return{
                    url: '/dea-import/create',
                    method: 'POST',
                    body: formData ,
                    credentials: 'include',
                    formData: true,
                }

            },
            invalidatesTags: ['DeaImport'],
          }),
          
        processDeaImport: build.mutation<ProcessDeaImportResponse, string>({
            query: (id: string) => ({
                url: '/dea-import/process-import',
                method: 'POST',
                body: {id},
                credentials: 'include',
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    deaImportApi.util.updateQueryData('getAllDeaImports', undefined as void, (draft) => {
                        const foundObject = draft.imports.find(obj => obj.id === id);
                        if (foundObject) {
                            foundObject.isLoading = true;
                        }  
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: ['DeaImport'],
        }),
        deleteDeaImport: build.mutation<void, string>({
            query: (id: string) => ({
                url: `/dea-import/delete/${id}`,
                method: 'POST',
                credentials: 'include',
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    deaImportApi.util.updateQueryData('getAllDeaImports', undefined as void, (draft) => {
                        const foundObject = draft.imports.find(obj => obj.id === id);
                        if (foundObject){

                            foundObject.isDeleting = true;
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: (result, error) => error ? [] : ['DeaImport'],
        }),
    })
})

export const {
    useGetAllDeaImportsQuery,
    useCreateDeaImportMutation,
    useProcessDeaImportMutation,
    useDeleteDeaImportMutation,
} = deaImportApi