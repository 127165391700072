import * as Yup from 'yup';

export const loginFormValidationSchema = Yup.object().shape({
    // fullname: Yup.string().required('Fullname is required'),
    // username: Yup.string()
    //   .required('Username is required')
    //   .min(6, 'Username must be at least 6 characters')
    //   .max(20, 'Username must not exceed 20 characters'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    password: Yup.string()
      .required('Password is required')
      .min(3, 'Password must be at least 3 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  export const createFlowValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .min(5, 'Title must be at least 3 characters')
      .max(150, 'Title must not exceed 40 characters'),
      description: Yup.string()
      .required('Description is required')
      .min(5, 'Description must be at least 3 characters')
      .max(150, 'Description must not exceed 40 characters'),
      type: Yup.string()
      .required('Type is required'),
      condition: Yup.string()
      .max(1000, 'Description must not exceed 1000 characters'),
  });

  export const createIqviaDetailValidationSchema = Yup.object().shape({
    schema: Yup.string()
      .required('Schema is required'),
    table_prefix: Yup.string()
      .required('Table prefix is required'),
    s3_folder: Yup.string()
    .required('S3 folder is required'),
    destination_schema: Yup.string()
      .required('Destination schema is required'),
      destination_table: Yup.string()
      .required('Destination table is required'),
    table_creator: Yup.string()
      .required('Table creator is required'),
  });

  export const updateUserValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Not a valid email')
      .required('Email is required'),
    role: Yup.string()
      .required('User role is required'),
  });

export const createUserValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Not a valid email')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6,'Password must contain 6 or more characters'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  role: Yup.string()
    .required('User role is required'),
});

export const createMassImportValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Title is required')
    .max(200, 'Title must not exceed 200 characters.'),
  description: Yup.string()
    .required('Description is required')
    .min(5, 'Description must contain at least 5 characters')
    .max(500, 'Description must not exceed 200 characters.'),
  type: Yup.string()
    .required('Entity type is required'),
  updateImportFiles: Yup.mixed()
    .test("required", "File with data is required", (value) => {
      console.log('value fromvalid', value.length);
      console.log('res', (value && value.length > 0));
      return value && value.length > 0;
    })
    .test('fileFormat', 'Unsupported file type', (value) => {
        const fileExt = value? value[0]?.name.split('.').pop():null;        
        return fileExt && ['xls', 'xlsx', 'csv'].includes(fileExt); 
    })
    .test('fileSize', 'File size should not exceed 5MB', (value) => {      
      const fileSize = value? value[0]?.size:0;
      return fileSize <= 5242880; 
  })
});

export const createCorrectImportValuesValidationSchema = Yup.object().shape({
  oldValue: Yup.string()
    .required('Old value is required'),
  newValue: Yup.string()
    .required('New value is required'),
  type: Yup.string()
    .required('Type is required'),
});