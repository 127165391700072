import { 
    USERS_ROUTE,
    LOGIN_ROUTE,
    IQVIA_DETAILS_ROUTE,
    STATISTIC_ROUTE,
    FLOWS_ROUTE,
    IMPORTS_MANAGEMENT_ROUTE,
    IMPORT_LOGS_ROUTE,
    IMPORT_DETAILS_ROUTE,
    IMPORTS_CHARGEBACK_ROUTE,
    IMPORT_CHARGEBACK_DETAIL_ROUTE,
    ROLES_ADMIN,
    IMPORT_CHARGEBACK_LOGS_ROUTE,
    IMPORTS_SALES_ROUTE,
    IMPORTS_SALES_DETAIL_ROUTE,
    IMPORTS_SALES_LOGS_ROUTE,
    IMPORTS_DEA_ROUTE,
    IMPORTS_DEA_DETAIL_ROUTE,
    IMPORTS_DEA_LOGS_ROUTE,
    UPDATED_CHARGEBACK_VALUES_ROUTE,
    CHARGEBACK_STATISTIC_ROUTE
} from './utils/contsants';
import {IAppRoute} from './models/IAppRoute';
import UsersPage from './pages/UsersPage';
import LoginPage from './pages/LoginPage';
import IqviaDetailsPage from './pages/IqviaDetailsPage';
import StatisticPage from './pages/StatisticPage';
import FlowsPage from './pages/Flow/FlowsPage';
import ImportManagenmentPage from './pages/ImportManagementPage';
import ImportLogsPage from './pages/ImportLogsPage';
import ImportChargebackPage from './pages/ImportChargebackPage';
import ImportSalesPage from './pages/ImportSalesPage';
import ImportDeaPage from './pages/ImportDeaPage';
import UpdateChargebackImportValuesPage from './pages/UpdateChargebackImportValuesPage';
import ChargebackStatisticPage from './pages/ChargebackStatisticPage';

export const authRoutes: IAppRoute[] = [
    {
        path: USERS_ROUTE,
        Component: UsersPage,
        roles: [ROLES_ADMIN]
    },
    {
        path: IQVIA_DETAILS_ROUTE,
        Component: IqviaDetailsPage,
        roles: [ROLES_ADMIN]
    },
    {
        path: STATISTIC_ROUTE,
        Component: StatisticPage
    },
    {
        path: FLOWS_ROUTE,
        Component: FlowsPage,
        roles: [ROLES_ADMIN]
    },
    {
        path: IMPORTS_MANAGEMENT_ROUTE,
        Component: ImportManagenmentPage
    },
    {
        path: IMPORT_DETAILS_ROUTE,
        Component: ImportManagenmentPage
    },
    {
        path: IMPORT_LOGS_ROUTE,
        Component: ImportLogsPage
    },
    {
        path: IMPORTS_CHARGEBACK_ROUTE,
        Component: ImportChargebackPage
    },
    {
        path: IMPORT_CHARGEBACK_DETAIL_ROUTE,
        Component: ImportChargebackPage
    },
    {
        path: IMPORT_CHARGEBACK_LOGS_ROUTE,
        Component: ImportLogsPage
    },

    {
        path: IMPORTS_SALES_ROUTE,
        Component: ImportSalesPage
    },
    {
        path: IMPORTS_SALES_DETAIL_ROUTE,
        Component: ImportSalesPage
    },
    {
        path: IMPORTS_SALES_LOGS_ROUTE,
        Component: ImportLogsPage
    },
    {
        path: IMPORTS_DEA_ROUTE,
        Component: ImportDeaPage
    },
    {
        path: IMPORTS_DEA_DETAIL_ROUTE,
        Component: ImportDeaPage
    },
    {
        path: IMPORTS_DEA_LOGS_ROUTE,
        Component: ImportLogsPage
    },
    {
        path: UPDATED_CHARGEBACK_VALUES_ROUTE,
        Component: UpdateChargebackImportValuesPage
    },
    {
        path: CHARGEBACK_STATISTIC_ROUTE,
        Component: ChargebackStatisticPage
    },
]

export const publicRoutes: IAppRoute[] =[
    {
        path: LOGIN_ROUTE,
        Component: LoginPage
    },
]