import React from 'react';
import { Table } from 'react-bootstrap';
import { IMassImport } from '../../models/MassImport/IMassImport';
import MassImportListItem from './MassImportListItem';

type MassImportListProps = {
    imports: IMassImport[];
    isAlreadyProcessing: boolean;
    onStartButtonClick: (id: string) => void;
    onDetailsButtonClick: (id: string) => void;
    onDeleteButtonClick: (id: string) => void;
    onDownloadProcessedButtonClick?: (id: string) => void;
}

const MassImportList: React.FC<MassImportListProps> = ({ 
    imports,
    isAlreadyProcessing,
    onStartButtonClick,
    onDetailsButtonClick,
    onDeleteButtonClick,
    onDownloadProcessedButtonClick,
}) => {

    if(imports.length === 0){
        return <div>
            There are no any items yet.
        </div>
    }

    return (
        <Table striped hover className="import-tb align-middle">
            <thead>
                <tr>
                    <th>
                        <div className='th-styled'>
                            Title
                        </div>
                    </th>
                    <th>
                        <div className='th-styled'>
                            Type
                        </div>
                    </th>
                    <th>
                        <div className='th-styled'>
                            Status
                        </div>
                    </th>
                    <th>
                        <div className='th-styled'>
                            File
                        </div>
                    </th>
                    <th>
                        <div className='th-styled'>
                            Created at
                        </div>
                    </th>
                    <th>
                        <div className='th-styled'>
                            #
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                {imports.map(importItem => <MassImportListItem
                                                key={importItem.id}
                                                importItem={importItem}
                                                isAlreadyProcessing={isAlreadyProcessing}
                                                onStartButtonClick={onStartButtonClick}
                                                onDetailsButtonClick={onDetailsButtonClick}
                                                onDeleteButtonClick={onDeleteButtonClick}
                                                onDownloadProcessedButtonClick={onDownloadProcessedButtonClick}  />)}
            </tbody>

        </Table>
    );
};

export default MassImportList;