import React, { useState } from 'react';
import { Modal, Alert, Form, FormControl, Button, Spinner } from 'react-bootstrap';
import { useCreateMassImportMutation } from '../../../store/api/massImportApi';
import { useForm } from 'react-hook-form';
import { CreateMassImportRequest } from '../../../store/api/types';
import { createMassImportValidationSchema } from '../../../utils/validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { MassImportType } from '../../../models/MassImport/MassImportType';
import { CreateMassImportFormInput } from '../../../store/api/types';
import { IImportTypeOption } from '../../../models/MassImport/IImportTypeOption';
import { MutationTrigger, UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { IMassImport } from '../../../models/MassImport/IMassImport';

type CreateMassImportModalProps = {
    // user: IUser;
    show: boolean;
    onHide: () => void;
    onSuccess: () => void;
    importTypes: IImportTypeOption [];
    // mutationFunction: MutationTrigger<MutationDefinition<CreateMassImportRequest, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, "IqviaDetail" | "Chargeback", IMassImport, "api" >>;

    createImport: UseMutation<MutationDefinition<CreateMassImportRequest, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>, "IqviaDetail" | "MassImport", IMassImport, "api" >>
}

const defaultErrorMsg = 'Oops. Something went wrong. Please, try again later. ';

const CreateMassImportModal: React.FC<CreateMassImportModalProps> = ({ show, onHide, onSuccess, importTypes, createImport }) => {
    
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
   
    // const [createMassImport, { isLoading, error }] = useCreateMassImportMutation();
    const [createMassImport, { isLoading, error }] = createImport();

    const onModalHide = () => {
        setErrorMsg(null);
        onHide();
    }

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<CreateMassImportFormInput>({
        resolver: yupResolver(createMassImportValidationSchema)
    });

    const selectFile = (e: any) => {
        console.log(e.target.files)
    }

    const onSubmit = async (data: CreateMassImportFormInput) => {

        // setShowAlert(null)
        console.log('Submit data', data)

        const requestData: CreateMassImportRequest = { ...data, updateFile: data.updateImportFiles[0] }

        try {
            const updateData = await createMassImport(requestData);
            console.log('Update Data', updateData);

            if ('error' in updateData) {
                console.log(updateData.error);
                const err = updateData.error as any;
                const errorMsg = err.data?.message || defaultErrorMsg;
                setErrorMsg(errorMsg)
                // setShowAlert(errorMsg)
            }
            else {
                reset()
                onSuccess()
            }
        }
        catch (err) {
            console.log('alert')
            // setShowAlert(defaultErrorMsg)
            console.log('error in catch: ', err);
        }
    }

    return (
        <Modal
            centered
            show={show}
            onHide={onModalHide}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add new data for update
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {errorMsg && <Alert variant='danger' onClose={() => setErrorMsg(null)} dismissible>
                    {errorMsg}
                </Alert>}
                <Form className='d-flex flex-column' onSubmit={handleSubmit(onSubmit)}>

                    <FormControl
                        {...register('title')}
                        className={`mt-3 ${errors.title ? 'is-invalid' : ''}`}
                        placeholder='Title'
                    />
                    <div className="invalid-feedback align-self-start">{errors.title?.message}</div>

                    <FormControl
                        {...register('description')}
                        className={`mt-3 ${errors.description ? 'is-invalid' : ''}`}
                        placeholder='Description'
                        as="textarea"
                        rows={3}
                        style={{'resize': 'none'}}
                    />
                    <div className="invalid-feedback align-self-start">{errors.description?.message}</div>

                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Import file</Form.Label>
                        <Form.Control
                            {...register('updateImportFiles')}
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv"
                        />
                        <div className="invalid-feedback align-self-start" style={{ "display": errors.updateImportFiles?.message ? 'block' : 'none'}}>{errors.updateImportFiles?.message}</div>
                    </Form.Group>

                    <Form.Select
                        {...register('type')}
                        aria-label="Role select"
                        className={`mt-3 ${errors.type ? 'is-invalid' : ''}`}>
                        <option value="">Choose entity type</option>
                        {importTypes.map(type => 
                            <option key={type.importType} value={type.importType}>{type.typeTitle}</option>
                        )}
                        {/* <option value={MassImportType.ACCOUNT}>Account</option>
                        <option value={MassImportType.CONTACT}>Contact</option> */}
                    </Form.Select>
                    <div className="invalid-feedback align-self-start">{errors.type?.message}</div>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='outline-danger' onClick={onHide}>Close</Button>
                <Button variant='outline-success' onClick={handleSubmit(onSubmit)}>
                    {isLoading && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />}
                    <span className="sr-only">Add</span>
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

export default CreateMassImportModal;